import React, { Component, lazy, Suspense } from "react";
import {
  Dimensions,
  ScrollView,
  View,
  Text,
  TextInput,
  TouchableOpacity,
  StyleSheet, Platform
} from "react-native";
// import { TabBar, TabView } from "react-native-tab-view";
// import ShadowSkeletonStar from "../components/ShadowSkeletonStar";
import ConstantFontFamily from "../constants/FontFamily";
// import { retry } from "../library/Helper";
import { Button, Icon } from "react-native-elements";
// import NavigationService from "../library/NavigationService";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import ButtonStyle from "../constants/ButtonStyle";
import Colors from "../constants/Colors";

import TrendingCliks from "./TrendingClicks"

import TrendingExternalFeed from "./TrendingExternalFeed"

import TrendingTopics from "./TrendingTopics"
import TrendingUsers from "./TrendingUsers"
// import BottomPart from "./BottomPart";
import { heightPercentageToDP as hp } from "react-native-responsive-screen";
import { leftPanelModalFunc } from '../reducers/AdminTrueFalseReducer'
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
// import { setUSERNAMEMODALACTION } from "../actionCreator/UsernameModalAction";

const OurMission = lazy(() => import("./OurMission"))

class TrendingTabs extends Component {
  state = {
    index: 3,
    routes: [
      // { key: "first", title: "Cliks", icon: "users", type: "font-awesome" },
      // { key: "second", title: "Topics", icon: "book", type: "font-awesome" },
      { key: "third", title: "Users", icon: "user", type: "font-awesome" },
      { key: "fourth", title: "Feeds", icon: "rss", type: "font-awesome" },
    ],
    searchedFollowText: this.props.searchedFollowText,
    focus: "Feeds"
  };

  constructor(props) {
    super(props);
  }

  // componentDidUpdate = async (prevProps) => {
  //   if (prevProps.searchedFollowText != this.props.searchedFollowText) {
  //     await this.setState({
  //       searchedFollowText: this.props.searchedFollowText,
  //     });
  //   }
  // };

  _renderTabBar = (props) => (
    // Dimensions.get("window").width >= 750 &&
    <View style={{ width: '15%', zIndex: 9999, backgroundColor: Colors.blueColor, marginRight: 15, }}>
      <TabBar
        {...props}
        indicatorStyle={{
          backgroundColor: "transparent",
          height: 1,
          borderRadius: 6,
          // width: "18.75%",
          // marginHorizontal: "3.125%"
        }}
        style={{
          backgroundColor: "#fff",
          shadowColor: "transparent",
          height: 60,

        }}
        contentContainerStyle={{
          flexDirection: 'column'
        }}
        labelStyle={[ButtonStyle.profileTitleStyle, {
          color: "#000",
          fontSize: 13,
          height: 60,
          justifyContent: "center",
        }]}
        // renderIcon={({ route, focused, color }) => (
        //   <Icon
        //     name={route.icon}
        //     type={route.type}
        //     color={focused ? route.title == 'Cliks' ? Colors.blueColor :
        //       route.title == 'Topics' ? Colors.greenColor :
        //         route.title == 'Users' ? '#000' :
        //           route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}
        //   />
        // )}
        renderLabel={({ route, focused, color }) => {
          return (
            <View style={{ height: 60, width: 70, justifyContent: 'center', alignItems: 'center', backgroundColor: focused ? '#fff' : Colors.blueColor, flexDirection: 'column' }}>
              <Icon
                name={route.icon}
                type={route.type}
                color={focused ?
                  route.title == 'Cliks' ? Colors.blueColor :
                    route.title == 'Topics' ? Colors.greenColor :
                      route.title == 'Users' ? '#000' :
                        route.title == 'Feeds' && Colors.orangeColor : "#D3D3D3"}

              />
            </View>
          )
        }}
      // renderIndicator={({ route, focused, color }) => null}
      />

    </View>
  );

  _handleIndexChange = (index) =>
    this.setState({ index, searchedFollowText: "" }, () => {
      this.props.activeIndex(index);
    });


  loginHandle = () => {
    this.props.leftPanelModalFunc(false)
    this.props.setLoginModalStatus(true);
  };


  changeTab = (title) => {
    if (title == "Cliks") {
      this.setState({
        index: 0,
        focus: "Cliks"
      })
    } else if (title == "Topics") {
      this.setState({
        index: 1,
        focus: "Topics"
      })
    } else if (title == "Users") {
      this.setState({
        index: 2,
        focus: "Users"
      })
    } else if (title == "Feeds") {
      this.setState({
        index: 3,
        focus: "Feeds"
      })
    }
  }

  inviteSignHandle = async () => {
    // await this.props.leftPanelModalFunc(false)
    // await this.props.setInviteUserDetail({
    //   clikName: "",
    //   inviteKey: "",
    //   userName: ""
    // });
    // await this.props.setUsernameModalStatus(true);
  };
  render() {
    return (

      <View style={{ height: "100%", flexDirection: "column", }}>
        <View
          style={[
            {
              height: Dimensions.get("window").width <= 750 ? hp("15%") : (Dimensions.get("window").height) / 5,
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: 'transparent',
              paddingVertical: 15,
              paddingHorizontal: 15,
            }
          ]}
        >


          <Suspense fallback={null}>

            <OurMission />
          </Suspense>

        </View>
        <View style={{ height: 60, backgroundColor: '#fff', flexDirection: 'row', width: '95%', marginLeft: '3%', justifyContent:'space-evenly'}}>
          {this.state.routes.map((route, index) => {
            // console.log(route);
            return (
              <TouchableOpacity key={index} style={{ height: 50, width: 60, justifyContent: 'center', alignItems: 'center', backgroundColor: '#fff' }} onPress={() => this.changeTab(route.title)}>
                <Icon
                  name={route.icon}
                  type={route.type}
                  color={
                    // route.title == 'Cliks' && this.state.focus == "Cliks" ? Colors.blueColor :
                    //   route.title == 'Topics' && this.state.focus == "Topics" ? Colors.blueColor :
                    route.title == 'Users' && this.state.focus == "Users" ? Colors.blueColor :
                      route.title == 'Feeds' && this.state.focus == "Feeds" ? Colors.blueColor : "#D3D3D3"}
                />
                {/* <Text >{route.title}</Text> */}
              </TouchableOpacity>
            )

          })}

        </View>
        <View style={{ width: '100%', paddingRight: 15, paddingLeft: 15 }}>

          {this.props.loginStatus != 1 && Dimensions.get("window").width <= 750 &&
            <View style={{ alignItems: 'center', }}>
              <View>
                {this.props.loginStatus == 0 && (
                  <View
                    style={{
                      flexDirection: "row",
                      marginTop: 15
                    }}
                  >

                    <Button
                      title="Log In"
                      titleStyle={{
                        fontSize: 14,
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#fff",
                      }}
                      buttonStyle={{
                        backgroundColor: "#000",
                        borderColor: Dimensions.get('window').width <= 750 ? "#f4f4f4" : "#fff",
                        borderRadius: 6,
                        borderWidth: 1,
                        alignSelf: "center",
                        width: 120,
                        paddingHorizontal: 20,
                        paddingVertical: 5,
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                      containerStyle={{
                        alignSelf: "center",
                        marginRight: 10,
                      }}
                      testID="welcome"
                      onPress={this.loginHandle}
                    />

                    <Button
                      title="Sign Up"
                      titleStyle={{
                        fontSize: 14,
                        fontFamily: ConstantFontFamily.defaultFont,
                        color: "#000",
                      }}
                      buttonStyle={{
                        backgroundColor: "#fff",
                        borderColor: Dimensions.get('window').width <= 750 ? "#000" : "#fff",
                        borderRadius: 6,
                        borderWidth: 1,
                        alignSelf: "center",
                        width: 120,
                        paddingVertical: 5

                      }}
                      containerStyle={{
                        alignSelf: "center",

                      }}
                      onPress={() => this.inviteSignHandle()}
                    />

                  </View>
                )}
              </View>
            </View>}
          <View style={{
            width: "100%",
            height: 40,
            justifyContent: "flex-start",
            flexDirection: "row",
            alignItems: "center",
            borderRadius: 8,
            borderColor: '#d3d3d3',
            borderWidth: 1,
            // paddingRight: "3%",
            marginVertical: 10,
            backgroundColor: '#fff'
          }}>
            <View style={{ width: "16%", marginRight: "auto" }}>
              <Icon name="search" size={18} type="font-awesome" />
            </View>
            <TextInput
              autoFocus={false}
              placeholder={
                // this.state.index == 0 ? "Search Cliks" :
                // this.state.index == 1 ? "Search Topics" :
                this.state.index == 2 ? 'Search User' :
                  this.state.index == 3 && "Search Feeds"}
              placeholderTextColor='#706969'
              onChangeText={(query) => this.props.onchangetext(query)}
              value={this.state.searchedWord}
              style={{
                height: 40,
                width: "74%",
                paddingHorizontal: 10,
                paddingVertical: 10,
                outline: "none",
                position: "absolute",
                left: "13%",
                // fontWeight: 'bold',
                fontFamily: ConstantFontFamily.defaultFont,
                color: Platform.OS == 'web' ? '#706969' : "#000"
              }}
            />
            {this.props.searchedFollowText ? (
              <TouchableOpacity
                onPress={() => this.props.onpress("")}
                style={{ marginLeft: "auto", width: "10%" }}
              >
                <Icon name="close" size={18} type="font-awesome" />
              </TouchableOpacity>
            ) : null}
          </View>
          {this.state.focus == "Cliks" ? <View
            style={{
              height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? Dimensions.get("window").height - 250 : (Platform.OS == 'web' && Dimensions.get("window").width > 1600) ? (Dimensions.get("window").height) * .62 :
                (Platform.OS == 'web' && Dimensions.get("window").width > 750 && Dimensions.get("window").width < 1600) ? (Dimensions.get("window").height) * .61:
                  hp('60%')

            }}
          >
            <ScrollView showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                borderWidth: 1,
                padding: 10,
                borderColor: Colors.lightGray,
                borderRadius: 8,
                height: '92%'
              }}>
              <ScrollView showsVerticalScrollIndicator={false}>

                <TrendingCliks
                  navigation={this.props.navigation}
                  searchedFollowText={this.state.searchedFollowText}
                />
              </ScrollView>
            </ScrollView>


          </View> : this.state.focus == "Topics" ? <View

            style={{
              // flex: 1,
              // width: '75%',
              // height: '80%',
              height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? Dimensions.get("window").height - 250 : (Platform.OS == 'web' && Dimensions.get("window").width > 1600) ? (Dimensions.get("window").height) * .62 :
                (Platform.OS == 'web' && Dimensions.get("window").width > 750 && Dimensions.get("window").width < 1600) ? (Dimensions.get("window").height) * .61:
                  hp('60%')
              // paddingRight: 15
            }}
          >

            <ScrollView showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                borderWidth: 1,
                padding: 10,
                borderColor: Colors.lightGray,
                borderRadius: 8,
                height: '92%'
              }}>
              <ScrollView showsVerticalScrollIndicator={false}>

                <TrendingTopics
                  navigation={this.props.navigation}
                  randomItemEvent={this.props.randomItemEvent}
                  searchedFollowText={this.state.searchedFollowText}
                />
              </ScrollView>
            </ScrollView>


          </View> : this.state.focus == "Users" ? <View

            style={{
              height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? Dimensions.get("window").height - 250 : (Platform.OS == 'web' && Dimensions.get("window").width > 1600) ? (Dimensions.get("window").height) * .62 :
                (Platform.OS == 'web' && Dimensions.get("window").width > 750 && Dimensions.get("window").width < 1600) ? (Dimensions.get("window").height) * .61:
                  hp('60%')
            }}
          >


            <ScrollView showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                borderWidth: 1,
                padding: 10,
                borderColor: Colors.lightGray,
                borderRadius: 8,
                height: '92%'
              }}>
              <ScrollView showsVerticalScrollIndicator={false}>

                <TrendingUsers
                  navigation={this.props.navigation}
                  searchedFollowText={this.state.searchedFollowText}
                // jumpTo='third'
                />
              </ScrollView>
            </ScrollView>

          </View> : this.state.focus == "Feeds" ? <View

            style={{
              height: (Platform.OS == 'web' && Dimensions.get("window").width <= 750) ? Dimensions.get("window").height - 250 : (Platform.OS == 'web' && Dimensions.get("window").width > 1600) ? (Dimensions.get("window").height) * .62 :
                (Platform.OS == 'web' && Dimensions.get("window").width > 750 && Dimensions.get("window").width < 1600) ? (Dimensions.get("window").height) * .61:
                  hp('60%')
            }}
          >

            <ScrollView showsVerticalScrollIndicator={false}
              contentContainerStyle={{
                borderWidth: 1,
                padding: 10,
                borderColor: Colors.lightGray,
                borderRadius: 8,
                height: '92%'
              }}>
              <ScrollView showsVerticalScrollIndicator={false}>

                <TrendingExternalFeed
                  navigation={this.props.navigation}
                  searchedFollowText={this.state.searchedFollowText}
                // jumpTo='fourth'
                />
              </ScrollView>
            </ScrollView>

          </View> : null}
        </View>
      </View >

    );
  }
}

const mapStateToProps = (state) => ({
  // getUsernameModalStatus: state.UsernameModalReducer.get("modalStatus"),
  // windowSize: state.windowResizeReducer.windowResize,
  loginStatus: state.UserReducer.loginStatus,

});

const mapDispatchToProps = (dispatch) => ({
  leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),

  // setSearchBarStatus: (payload) =>
  //   dispatch({ type: "SEARCH_BAR_STATUS", payload }),
  setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  // setInviteUserDetail: payload =>
  //   dispatch({ type: "SET_INVITE_USER_DETAIL", payload }),
  // setUsernameModalStatus: payload => dispatch(setUSERNAMEMODALACTION(payload)),
  // leftPanelModalFunc: payload => dispatch({ type: 'LEFT_PANEL_OPEN', payload }),

});

//export default TrendingTabs;
export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrendingTabs
);

const styles = StyleSheet.create({
  container: {
    fontSize: 44,
    height: 40,
    backgroundColor: "#000",
  },
});