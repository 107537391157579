// import { fromJS, List, } from "immutable";
import React, { lazy, Suspense } from "react";
// import { retry } from "../library/Helper";
import {
  Text,
  View,Platform
} from "react-native";
import { Icon } from "react-native-elements";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { getFeedProfileDetails } from "../actionCreator/FeedProfileAction";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import appolloClient from "../client";
import ConstantFontFamily from "../constants/FontFamily";
import { SearchFeedMutation } from "../graphqlSchema/SearchMutation";
import { SearchFeedVariables } from "../graphqlSchema/SearchVariables";
import TrendingExternalFeed from './TrendingExternalFeed';


// const TrendingExternalFeed = lazy(() =>
//   retry(() => import("./TrendingExternalFeed"))
// );

class FeedListDrawerScreen extends React.PureComponent {
  constructor(props) {
    super(props);
    this.timer;
    this.state = {
      showEditIcon: true,
      showSearchIcon: true,
      search: "",
      userlistData: this.props.getUserFollowFeedList,
      showTooltip: false,
      listTrending_feeds: this.props.listTrending_feeds
    };
  }

  goToProfile = async id => {
    await this.props.setFeedDetails({
      id: id,
      type: "feed"
    });
    this.props.setPostCommentReset({
      payload: [],
      postId: "",
      title: "",
      loading: true
    });
  };
  updateUserSearch = search => {
    this.setState({ search });
    let tempData = [];
    let tempArray = [];
    this.setState({ search });
    if (search.length > 0 && this.props.current === 3) {
      SearchFeedVariables.variables.prefix = search;
      appolloClient
        .query({
          query: SearchFeedMutation,
          ...SearchFeedVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          tempArray = res.data.search.feeds;
          for (let i = 0; i < tempArray.length; i++) {
            tempData.push({ feed: tempArray[i] });
          }
          this.setState({
            userlistData: fromJS(tempData),
            listTrending_feeds: []
          });
        });
    } else {
      this.setState({
        userlistData: this.props.getUserFollowFeedList,
        listTrending_feeds: this.props.listTrending_feeds
      });
    }
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.searchedWord != this.props.searchedWord) {
      await this.updateUserSearch(this.props.searchedWord);
    } else if (prevProps.searchedWord.length == 0) {
      this.setState({ userlistData: this.props.getUserFollowFeedList });
    }
  };

  componentWillUnmount = () => {
    clearTimeout(this.timer);
  };
  toogle = () => {
    if (this.state.showTooltip == false) {
      this.setState({ showTooltip: true });
    } else {
      this.setState({ showTooltip: false });
    }
  };

  calHeightLeftPannel(height) {
    if (height > 0) {
      if (this.props.current == 2) {
        this.props.calHeightLeftPannel(height);
      }
    }
  }

  loginHandle = () => {
    this.props.setLoginModalStatus(true);
  };

  render() {
    return (
      // <View>
      /* {this.props.loginStatus == 1 ? ( */
      <View>

        <TrendingExternalFeed
          navigationRef={Platform.OS == 'web' ? this.props.navigationRef :this.props.navigationRef}
          searchedFollowText={this.props.searchedWord}
        />

      </View>
     
    );
  }
}

const mapStateToProps = state => ({
  // getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
  //   ? state.LoginUserDetailsReducer.userFollowFeedList
  //   : [],
  // loginStatus: state.UserReducer.get("loginStatus"),
  // listTrending_feeds: !state.TrendingExternalFeedsReducer.getIn([
  //   "Trending_ExternalFeeds"
  // ])
  //   ? List()
  //   : state.TrendingExternalFeedsReducer.getIn(["Trending_ExternalFeeds"])
});

const mapDispatchToProps = dispatch => ({
  // setLoginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  // setFeedDetails: payload => dispatch(getFeedProfileDetails(payload)),
  // setPostCommentReset: payload =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload })
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  FeedListDrawerScreen
);

