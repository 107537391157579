import React, { Component } from "react";
import { Text, View } from "react-native";
import { connect } from "react-redux";
import { compose } from "react-recompose";
// import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
// import { setSIGNUPMODALACTION } from "../actionCreator/SignUpModalAction";
import { phrases } from "../constants/WeclikdIntro";
import ButtonStyle from "../constants/ButtonStyle";

class OurMission extends Component {
  constructor(props) {
    super(props);
    this.state = {
      content: phrases,
      index: 0,
    };
  }

  // openLoginModal = async () => {
  //   this.props.setLoginModalStatus(true);
  // };

  // signHandle = async () => {
  //   this.props.setSignUpModalStatus(true);
  // };

  componentDidMount() {
    this.reCondition();
  }

  reCondition = () => {
    let __self = this;
    this.time = setInterval(() => {
      if (__self.state.index + 1 == __self.state.content.length) {
        __self.setState({
          index: 0,
        });
      } else {
        __self.setState({
          index: __self.state.index + 1,
        });
      }
    }, 30000);
  };

  componentWillUnmount() {
    if (this.time > 0) {
      clearInterval(this.timer);
    }
  }

  render() {
    
    return (
      <View>
        <Text
          style={[
            ButtonStyle.profileTitleStyle,
            {
              textAlign: "center",
              fontSize:
                this.state.content[this.state.index].length > 85 ? 18 : 20,
              fontWeight: "bold",
              marginTop:this.state.content[this.state.index].length > 85 ? 10:0
            },
          ]}
        >
          {this.state.content[this.state.index]}
        </Text>
      </View>
    );
  }
}



const mapDispatchToProps = (dispatch) => ({
  // setSignUpModalStatus: (payload) => dispatch(setSIGNUPMODALACTION(payload)),
  // setLoginModalStatus: (payload) => dispatch(setLOGINMODALACTION(payload)),
});

export default compose(connect(null, mapDispatchToProps))(
  OurMission
);
