import React, { useRef } from "react";
import { Dimensions } from "react-native";
// import { createDrawerNavigator } from '@react-navigation/drawer';
import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';
import DashboardScreen from '../screens/DashboardScreen';
import PostDetailsScreen from '../screens/PostDetailsScreen'
import HeaderRight from '../components/HeaderRight';
import CliksProfileScreen from "../screens/CliksProfileScreen";
import TopicScreen from '../screens/TopicScreen';
import ProfileScreen from "../screens/ProfileScreen";
import SearchInputWeb from '../components/SearchInputWeb';
import ExternalFeedScreen from "../screens/ExternalFeedScreen";
import CreateClikScreen from '../screens/CreateClikScreen';
import TopicHierarchyScreen from '../screens/TopicHierarchyScreen'
import NotificationScreen from '../screens/NotificationScreen'
import AddFeedScreen from '../screens/AddFeedScreen';
import SettingsScreen from '../screens/SettingsScreen';
import CreatePostScreen from '../screens/CreatePostScreen';
import Layout from '../components/Layout';
import EditClikScreen from '../screens/EditClikScreen'
import TermsAndConditionsScreen from '../screens/TermsAndConditionsScreen';
import PrivacyPolicyScreen from '../screens/PrivacyPolicyScreen';
import FAQScreen from '../screens/FAQScreen';
import CreateTopicScreen from '../screens/CreateTopicScreen';
import RelatedPostScreen from '../screens/RelatedPostScreen';
import SearchScreen from '../screens/SerachScreen';
import EditPostScreen from '../screens/EditPostScreen';
import FloatingFooter from '../components/FloatingFooter'
import 'react-native-gesture-handler';
import CreateCommentCard from "../components/CreateCommentCard";
import AsyncStorage from '@react-native-async-storage/async-storage';
import Header from "../components/Header";
import { connect } from "react-redux";
import { compose } from "react-recompose";

const Stack = createStackNavigator();


const linking = {
    prefixes: [
        /* your linking prefixes */
    ],
    // config: {
    //     /* configuration for matching screens with paths */
    //     screens: {
    //         home: {
    //             path: '',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         },
    //         clikProfile: {
    //             path: 'clik/:id/feed/:postId',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         },
    //         topicProfile: {
    //             path: 'topic/:id',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         },
    //         userProfile: {
    //             path: 'user/:id',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         },
    //         feedProfile: {
    //             path: 'feed/:id',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         },
    //         relatedPost: {
    //             path: 'post/:id',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         },
    //         commentPost: {
    //             path: 'post/:id/:idd',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         },
    //         search: {
    //             path: 'search',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },

    //         }, editPost: {
    //             path: 'editPost',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },


    //         }, editFeed: {
    //             path: 'editFeed',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },


    //         }, invite: {
    //             path: 'invite/:username',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },
    //         },
    //         analytics: {
    //             path: 'analytics',
    //             parse: {
    //                 //   id: (id) => `user-${id}`,
    //             },
    //         },
    //         notification: {
    //             path: 'notification'
    //         },
    //         userProfile: {
    //             path: 'user/:id'
    //         },
    //         feedProfile: {
    //             path: 'feed/:id'
    //         }


    //     }
    // },
};


const AppNavigator = (props) => {
    const navigationRef = useRef(null);

    // console.log(navigationRef, 'navigationRef==============')

    return (
        <NavigationContainer ref={navigationRef} >
            <Layout
                navigationRef={navigationRef}
            />
            <Stack.Navigator>
                <Stack.Screen name="home"
                    options={({ navigation }) => ({
                        headerShown: false,
                        title: "Weclikd",
                        // header: () => (
                        //   <Header
                        //     {...props}
                        //     title="home"
                        //     navigation={navigation}
                        //     hi={'hiii'}
                        //     //  navigation={this.props.navigation}
                        //     // leftPanelModalFunc={props.leftPanelModalFunc}
                        //   />
                        // ),
                    })}
                    component={DashboardScreen}

                />

                <Stack.Screen name="Discussion"
                    options={({ navigation }) => ({
                        headerShown: Dimensions.get("window").width < 750 ? true : false,
                        title: "Weclikd",
                        header: (props) => (
                            <Header
                                title="Discussion"
                                hi={'hii'}
                                navigation={navigation}
                                // leftPanelModalFunc={this.props.leftPanelModalFunc}
                                {...props}
                            />
                        ),
                    })}
                    component={PostDetailsScreen}
                />
                <Stack.Screen name="TermsAndConditionsScreen"
                    options={{ headerShown: false, title: "TermsAndConditionsScreen" }}
                    component={TermsAndConditionsScreen}
                />
                <Stack.Screen name="PrivacyPolicyScreen"
                    options={{ headerShown: false, title: "PrivacyPolicyScreen" }}
                    component={PrivacyPolicyScreen}
                />
                <Stack.Screen name="FAQScreen"
                    options={{ headerShown: false, title: "FAQScreen" }}
                    component={FAQScreen}
                />


                <Stack.Screen name="clikProfile"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={CliksProfileScreen}
                />
                <Stack.Screen name="topicProfile"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={TopicScreen}
                />
                <Stack.Screen name="userProfile"
                    options={({navigation})=>({
                        headerShown: Dimensions.get("window").width < 750 ? true : false,
                        title: "Weclikd",
                        header: () => (
                          <Header
                            // {...props}
                            title={"@" + props?.userDetails?.username}
                            navigation={navigation}
                            leftPanelModalFunc={props.leftPanelModalFunc}
                          />
                        ),
                      })}
                    component={ProfileScreen}
                />
                <Stack.Screen name="feedProfile"
                    options={({navigation})=>({
                        headerShown: Dimensions.get("window").width < 750 ? true : false,
                        title: "Weclikd",
                        header: (prop) => (
                          <Header
                            title={props.feedDetails.name}
                            hi={'hii'}
                            navigation={navigation}
                            // leftPanelModalFunc={this.props.leftPanelModalFunc}
                            {...prop}
                          />
                        ),
                      })}
                    component={ExternalFeedScreen}
                />
                {/* <Stack.Navigator> */}
                <Stack.Screen name="createClik"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={CreateClikScreen}
                />
                <Stack.Screen name="createTopic"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={CreateTopicScreen}
                />

                <Stack.Screen name="comment"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={CreateCommentCard}
                />



                <Stack.Screen name="topichierarchy"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={TopicHierarchyScreen}
                />
                <Stack.Screen name="Notification"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={NotificationScreen}
                />

                <Stack.Screen name="addFeed"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={AddFeedScreen}
                />
                <Stack.Screen name="settings"
                    options={({navigation})=>({
                        headerShown: Dimensions.get("window").width < 750 ? true : false,
                        title: "Weclikd",
                        header: (props) => (
                          <Header
                            title="Settings"
                            hi={'hii'}
                            navigation={navigation}
                            // leftPanelModalFunc={this.props.leftPanelModalFunc}
                            {...props}
                          />
                        ),
                      })}
                    component={SettingsScreen}
                />
                <Stack.Screen name="createPost"
                    options={({navigation})=>({
                        headerShown: Dimensions.get("window").width < 750 ? true : false,
                        title: "Weclikd",
                        header: (props) => (
                          <Header
                            title="Create Post"
                            hi={'hii'}
                            navigation={navigation}
                            // leftPanelModalFunc={this.props.leftPanelModalFunc}
                            {...props}
                          />
                        ),
                      })}
                    component={CreatePostScreen}
                />
                <Stack.Screen name="editClik"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={EditClikScreen}
                />
                <Stack.Screen name="termsandconditions"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={TermsAndConditionsScreen}
                /><Stack.Screen name="privacyPolicy"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={PrivacyPolicyScreen}
                /><Stack.Screen name="relatedPost"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={RelatedPostScreen}
                /><Stack.Screen name="search"
                options={({navigation})=>({
                    headerShown: Dimensions.get("window").width < 750 ? true : false,
                    title: "Weclikd",
                    header: (props) => (
                      <Header
                        title="Search"
                        hi={'hii'}
                        navigation={navigation}
                        // leftPanelModalFunc={this.props.leftPanelModalFunc}
                        {...props}
                      />
                    ),
                  })}
                    component={SearchScreen}
                /><Stack.Screen name="editPost"
                    options={{ headerShown: false, title: "Weclikd" }}
                    component={EditPostScreen}
                />

                {/* </Stack.Navigator> */}

            </Stack.Navigator>
            {Dimensions.get('window').width <= 750 ?
                <FloatingFooter navigationRef={navigationRef} /> : null}
        </NavigationContainer>
    )
}


const mapStateToProps = (state) => ({
    loginStatus: state.UserReducer.loginStatus,
    isAdmin: state.AdminTrueFalseReducer.isAdmin,
    isAdminView: state.AdminTrueFalseReducer.isAdminView,
    windowSize: state.windowResizeReducer.windowResize,
    screenName: state.screenNameReducer.screen,
    userDetails: state.UserProfileDetailsReducer.getCurrentUserProfileDetails,
    feedDetails: state.FeedProfileReducer.getFeedProfileDetails,
  
  });

const mapDispatchToProps = (dispatch) => ({
    changeLoginStatus: (payload) => dispatch(setLoginStatus(payload)),
    windowResize: (payload) => dispatch(windowResize(payload)),
    leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),
    changeAdminStatus: (payload) => dispatch(setAdminStatus(payload)),
  });
  
  export default compose(connect(mapStateToProps, mapDispatchToProps))(
    AppNavigator
  );