// import { List } from "immutable";
import React from "react";
import {
  Image,
  Text,
  TouchableOpacity,
  View,
  FlatList,
  ActivityIndicator,
  Dimensions, Platform
} from "react-native";
import { Hoverable } from "react-native-web-hooks";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import appolloClient from "../client";
import { getFeedProfileDetails } from "../actionCreator/FeedProfileAction";
import ExternalFeedStar from "./ExternalFeedStar";
import { SearchFeedMutation } from "../graphqlSchema/SearchMutation";
import { SearchFeedVariables } from "../graphqlSchema/SearchVariables";
import { Trending_ExternalFeeds_Mutation } from "../graphqlSchema/TrendingMutation";
// import { IndexTab } from '../reducers/action/Completed'
import ButtonStyle from "../constants/ButtonStyle";
// import { feedFluctuation, screen } from '../reducers/action/Completed';
import ConstantColors from "../constants/Colors";
import { feedIconUpload, setIndexes } from '../reducers/AdminTrueFalseReducer';
import { setTermWeb, setTerm } from '../reducers/ScreenLoadingReducer';
import { leftPanelModalFunc } from '../reducers/AdminTrueFalseReducer';
import { screen } from '../reducers/ScreenNameReducer'




class TrendingExternalFeed extends React.PureComponent {
  constructor(props) {
    super(props);
    this.onEndReachedCalledDuringMomentum = true;
    this.viewabilityConfig = {
      viewAreaCoveragePercentThreshold: 50
    };
    this.state = {
      searchedFollowText: this.props.searchedFollowText,
      listTrending_feeds: [],
      page: 1,
      loading: true,
      loadingMore: false,
      refreshing: false,
      pageInfo: null,
      error: null,
      apiCall: true,
      followError: false
    };
  }

  goToProfile = id => {
    // this.props.IndexTab(0)
    this.props.screen('feedScreen')
    // this.props.setIndexes("Feeds")

    this.props.setFeedDetails({
      id: "ExternalFeed:"+id,
      type: "feed"
    });

    this.props.setTermWeb('');
    this.props.setTerm('');
    if (Platform.OS == 'web') {
      // console.log(this.props, 'his.props.navigationRef');
      this.props.navigationRef.navigate('feedProfile', { id: id })

    } else {
      this.props.navigationRef?.current?.navigate("feedProfile", { id: id })
    }

    this.props.leftPanelModalFunc(false)
    // this.props.feedFluctuation(true)
  };

  componentDidUpdate = async prevProps => {
    if (prevProps.searchedFollowText != this.props.searchedFollowText) {
      await this.feedSearch(this.props.searchedFollowText);
    }
    else if (prevProps.getFeedIcon != this.props.getFeedIcon) {
      await this.setState({
        pageInfo: null,
      });
      this.getTrendingFeedList();
    }
  };

  feedSearch = search => {
    this.setState({ search });
    let tempData = [];
    let tempArray = [];

    if (search.length > 0) {
      SearchFeedVariables.variables.prefix = search;
      appolloClient
        .query({
          query: SearchFeedMutation,
          ...SearchFeedVariables,
          fetchPolicy: "no-cache"
        })
        .then(res => {
          tempArray = res.data.search.feeds;
          for (let i = 0; i < tempArray.length; i++) {
            tempData.push({ node: tempArray[i] });
          }
          this.setState({
            listTrending_feeds: tempData
          });
        });
    } else {
      this.setState({ pageInfo: null }, () => {
        this.getTrendingFeedList();
      })
    }
  };

  componentDidMount = () => {
    this.props.updateFeedStar(false)
    this.getTrendingFeedList();
  };

  _handleRefresh = () => {
    this.setState(
      {
        page: 1,
        refreshing: true
      },
      () => {
        this.getTrendingFeedList();
      }
    );
  };

  _handleLoadMore = distanceFromEnd => {
    this.setState(
      (prevState, nextProps) => ({
      }),
      () => {
        if (
          0 <= distanceFromEnd &&
          distanceFromEnd <= 5 &&
          this.state.apiCall == true
        ) {
          this.setState({
            apiCall: false
          });
          this.getTrendingFeedList();
        }
      }
    );
  };

  _renderFooter = () => {
    if (!this.state.loadingMore || this.state.followError) return null;
    return (
      <View>
        {this.state.listTrending_feeds.length > 0 ? <ActivityIndicator animating size="large" color="#000" /> : null}
      </View>
    );
  };

  getTrendingFeedList = () => {
    let __self = this;
    const { page, pageInfo } = this.state;

    if (pageInfo == null || this.props.getFeedIcon == false) {
      this.setState({
        loadingMore: true
      });
      appolloClient
        .query({
          query: Trending_ExternalFeeds_Mutation,
          variables: {
            first: 20,
            after: null,
            sort_type: "TRENDING",
            type: "ExternalFeed"
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          // alert(JSON.stringify(res))
          __self.setState({
            loading: false,
            // listTrending_feeds: res.data.node_list.edges,
            listTrending_feeds: this.getFeedList(res.data.node_list.edges),
            pageInfo: res.data.node_list.pageInfo,
            page: page + 1,
            apiCall: true,
            loadingMore: false,
            followError: false
          }, () => {
            this.props.updateFeedStar(true)
          });
        })
        .catch(e => {
          console.log(e);
          // this.setState({
          //   followError: true
          // })
        });
    } else if (pageInfo != null && pageInfo.hasNextPage == true) {
      this.setState({
        loadingMore: true
      });
      appolloClient
        .query({
          query: Trending_ExternalFeeds_Mutation,
          variables: {
            first: 20,
            after: pageInfo ? pageInfo.endCursor : null,
            sort_type: "TRENDING",
            type: "ExternalFeed"
          },
          fetchPolicy: "no-cache"
        })
        .then(res => {
          __self.setState({
            loading: false,
            listTrending_feeds: __self.state.listTrending_feeds.concat(
              // res.data.node_list.edges
              this.getFeedList(res.data.node_list.edges)
            ),
            pageInfo: res.data.node_list.pageInfo,
            apiCall: true,
            loadingMore: false,
            followError: false
          }, () => {
            // this.props.updateFeedStar(false)
          });
        })
        .catch(e => {
          console.log(e);
          // this.setState({
          //   followError: true
          // })
        });
    }
  };

  onViewableItemsChanged = ({ viewableItems, changed }) => {
    let perLoadDataCount = 20;
    let halfOfLoadDataCount = perLoadDataCount / 2;
    let lastAddArr =
      this.state.listTrending_feeds.length > 0 &&
      this.state.listTrending_feeds.slice(-perLoadDataCount);
    try {
      if (lastAddArr[halfOfLoadDataCount] && viewableItems.length > 0) {
        viewableItems.find(item => {
          let feedId = item.item.node ? item.item.node.id : item.item.feed.id;
          let LastfeedId = lastAddArr[halfOfLoadDataCount].node
            ? lastAddArr[halfOfLoadDataCount].node.id
            : lastAddArr[halfOfLoadDataCount].feed.id;
          if (feedId === LastfeedId) {
            this._handleLoadMore(0);
          }
        });
      }
    } catch (e) {
      console.log(e, "lastAddArr", lastAddArr[halfOfLoadDataCount]);
    }
  };

  getFeedList = item => {

    let newArray = [];
    let getFollowList = []
    let newArray1 = []

    // if (this.state.listTrending_feeds.length > 0) {
    //   item.forEach((obj) => {
    //     let index = this.props.getUserFollowFeedList.findIndex(
    //       (i) => i.node.topic.id == obj.node.id
    //     );
    //     if (index == -1) {
    //       newArray.push({ ...obj });
    //     }
    //   });
    //   return newArray
    // } else {

    if (item.length > 0) {
      item.forEach((obj) => {
        let index = this.props.getUserFollowFeedList?.findIndex(
          (i) => i.node.external_feed.name == obj.node.name
        );
        if (index == -1) {
          newArray.push({ ...obj });
        }
      });
    }

    this.props.getUserFollowFeedList?.map(e => {
      getFollowList.push({ node: e.node.external_feed, __typename: "WeclikdNodeEdge" })
    })

    newArray1 = getFollowList.concat(newArray)
    return newArray1
    // }

  };

  _renderItem = item => {
    var row = item.item.node ? item.item.node : item.item.feed;
    return (
      <Hoverable>
        {isHovered => (
          <View
            key={item.index}
            style={{
              overflow: "hidden",
              padding: 2,
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              backgroundColor: '#fff',
            }}
          >

            <TouchableOpacity
              onPress={() =>
                this.goToProfile(row?.name ? row.name.replace("ExternalFeed:", "") : row.external_feed.name.replace("ExternalFeed:", ""))
              }
              style={{
                flexDirection: "row",
                borderRadius: 4,
                overflow: "hidden",
                width: "85%"
              }}
            >
              <View
                style={{
                  // marginVertical: 5,
                  flexDirection: "row",
                  backgroundColor: "rgba(255,255,255,0.5)"
                }}
              >
                {/* {row.profile_pic ? ( */}
                <Image
                  source={(row?.profile_pic) ? {
                    uri: row?.profile_pic
                  } : require("../assets/favicon.png")}
                  style={{
                    width: 30,
                    height: 30,
                    borderRadius: 6
                  }}
                />


              </View>
              <View
                style={{
                  alignSelf: "center",
                  marginLeft: '5%'
                }}
              >
                <Text
                  style={[ButtonStyle.clikNameTitleStyle, {
                    textAlign: "left",
                    textDecorationLine: isHovered == true ? "underline" : "none",
                    color: '#000'
                  }]}
                >

                  {row?.name ? row.name : row.external_feed.name}{" "}
                </Text>
              </View>
            </TouchableOpacity>

            <ExternalFeedStar
              FeedName={row?.name ? row.id.toLowerCase() : row.external_feed.id.toLowerCase()}
              FeedId={row.id}
              ContainerStyle={{
                flex: 1,
                justifyContent: "flex-end",
                width: "20%",
                paddingLeft: 15
              }}
              ImageStyle={{
                height: 16,
                width: 16,
                alignSelf: "flex-end"
              }}
              updateStar={this.updateStar}
            />
          </View>
        )}
      </Hoverable>
    );
  };

  updateStar = async () => {
    await this.setState({
      pageInfo: null,
    });
    this.props.updateFeedStar(false)
    this.getTrendingFeedList();
  };

  render() {
    // console.log(this.state.listTrending_feeds, 'this.state.listTrending_feeds')
    // console.log(this.state.followError,'this.state.followErrorthis.state.followErrorthis.state.followError');
    return (
      <View style={{ height: '100%' }}>
        
        <FlatList
          extraData={this.state}
          contentContainerStyle={{
            flexDirection: "column",
            height: '100%',
            width: "100%"
          }}
          data={this.state?.listTrending_feeds}
          keyExtractor={(item, index) => index.toString()}
          renderItem={this._renderItem}
          showsVerticalScrollIndicator={false}
          onRefresh={this._handleRefresh}
          refreshing={this.state.refreshing}
          onEndReachedThreshold={0.2}
          initialNumToRender={10}
          ListFooterComponent={this._renderFooter}
          ListHeaderComponent ={() => {
            return (
              <View>
              </View>
            )
          }}
          onViewableItemsChanged={this.onViewableItemsChanged}
          viewabilityConfig={this.viewabilityConfig}
        /> 

      </View>
    );
  }
}

const mapStateToProps = state => ({
  // listTrending_feeds: !state.TrendingExternalFeedsReducer.getIn([
  //   "Trending_ExternalFeeds"
  // ])
  //   ? List()
  //   : state.TrendingExternalFeedsReducer.getIn(["Trending_ExternalFeeds"]),
  getUserFollowFeedList: state.LoginUserDetailsReducer.userFollowFeedList
    ? state.LoginUserDetailsReducer.userFollowFeedList
    : [],
  getFeedIcon: state.AdminTrueFalseReducer.feedIconUpload,
  loginStatus: state.UserReducer.loginStatus,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,


});

const mapDispatchToProps = dispatch => ({
  setFeedDetails: payload => dispatch(getFeedProfileDetails(payload)),
  setIndexes: (payload) => dispatch(setIndexes(payload)),
  updateFeedStar: payload => dispatch(feedIconUpload(payload)),
  setTerm: (payload) => dispatch(setTerm(payload)),
  setTermWeb: (payload) => dispatch(setTermWeb(payload)),

  // setPostCommentReset: payload =>
  //   dispatch({ type: "POSTCOMMENTDETAILS_RESET", payload }),
  leftPanelModalFunc: payload => dispatch(leftPanelModalFunc(payload)),
  screen: (payload) => dispatch(screen(payload)),

  // IndexTab: payload => dispatch(IndexTab(payload)),
  // feedFluctuation: payload => dispatch(feedFluctuation(payload)),
  // screen: (payload) => dispatch(screen(payload))


});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  TrendingExternalFeed
);
