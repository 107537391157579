import * as React from 'react';
import { createRef, Suspense } from "react";
import {
  Animated,
  Dimensions,
  Platform,
  Text,
  TouchableOpacity,
  View,
  Image,

} from "react-native";
import { Icon } from "react-native-elements";
import {
  Menu,
  MenuTrigger, MenuOption, MenuOptions
} from "react-native-popup-menu";
import { connect } from "react-redux";
import { compose } from "react-recompose";
import { setLOGINMODALACTION } from "../actionCreator/LoginModalAction";
import ShadowSkeleton from "../components/ShadowSkeleton";
import ConstantFontFamily from "../constants/FontFamily";
import ButtonStyle from "../constants/ButtonStyle";
import { screen } from '../reducers/ScreenNameReducer'
import Colors from "../constants/Colors";
import {
  setLoginStatus
} from "../actionCreator/UserAction";
import { showDiscussionReducer } from '../reducers/AdminTrueFalseReducer';
import TrendingHomeFeed from '../components/TrendingHomeFeed'
import NewHomeFeed from '../components/NewHomeFeed';
import DiscussionHomeFeed from '../components/DiscussionHomeFeed';
import LeftPanel from '../components/LeftPanel';
import CommentDetailScreen from './CommentDetailScreen';
import { setTerm } from '../reducers/ScreenLoadingReducer';
import { setInviteLinkStatus } from '../reducers/AdminTrueFalseReducer';
import { setUSERNAMEMODALACTION } from "../reducers/AdminTrueFalseReducer";
import { leftPanelModalFunc } from '../reducers/AdminTrueFalseReducer';
import { searchOpenBarStatus } from '../reducers/AdminTrueFalseReducer';
import HeaderRightDashboard from '../components/HeaderRightDashboard';
import SearchInputWeb from '../components/SearchInputWeb';


class DashboardScreen extends React.PureComponent {
  state = {
    commentDelay: false,
    feedY: 0,
    showSearchIcon: true,
    tabPost: false,
    indexStatus: false,
    setHeaderIcon: 'Trending',
    login: "1",
    modalVisible: false,
    focused: "Trending",
  };

  constructor(props) {
    super(props);
    this.nameInput = createRef()
  }

  componentDidMount = async () => {
    this.props.searchOpenBarStatus(false)
    this.props.loginModalStatus(false)
    if (!this.props.profileData?.data?.user) {
      this.props.changeLoginStatus(0)
    }
    this.props.showDiscussion(false)
    this.setState({ focused: "Trending" })

    this.props.setTerm('');

    if (Platform.OS == "web") {

      if (window.location.href.toString().includes('invite') == true) {
        this.props.setInviteModal(true)
        this.props.setUsernameModalStatus(true)
      }
    }

    // let Domain = window.location.href
    //   .replace("http://", "")
    //   .replace("https://", "")
    //   .replace("www.", "")
    //   .split(/[/?#]/)[1];



    this.props.screen("home")

  };


  selectTab = (tabName) => {
    this.setState({ setHeaderIcon: tabName })
  }



  goToScrollTop = () => {
    this.props.leftPanelModalFunc(true)
    this.setState({ modalVisible: true })
  };



  _renderScene = () => {
    const { focused } = this.state
    const { loginStatus, navigation } = this.props
    if (focused == "Discussion") {
      return (
        loginStatus == 1 ? (
          <View style={{ flex: 1 }}>

            <DiscussionHomeFeed
              navigation={navigation}
              listType={"Home"}
              ActiveTab={''}
            />
          </View>
        ) : (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon
              color={"#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
              reverse
              name="sticky-note"
              type="font-awesome"
              size={20}
              containerStyle={{
                alignSelf: "center"
              }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#000",
                alignSelf: "center"
              }}
            >
              <Text
                onPress={() => this.loginHandle()}
                style={{
                  textDecorationLine: "underline",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Login
              </Text>{" "}
              to see bookmarked posts
            </Text>
          </View>
        ))
    }

    else if (focused == "New") {
      return (
        <NewHomeFeed
          navigation={navigation}
          listType={"Home"}
          ActiveTab={''}
        />


      )

    }
    else if (focused == "Trending") {
      return (
        <View style={{ flex: 1 }}>
          <TrendingHomeFeed
            navigation={navigation}
            listType={"Home"}
            ActiveTab={''}

          />
        </View>
      )
    }

  };

  renderTabViewForMobile = () => {

    const { setHeaderIcon } = this.state

    const { tabType, loginStatus, navigation } = this.state


    if (setHeaderIcon == "Bookmarks" || tabType == "Bookmarks") {
      return (
        loginStatus == 1 ? (
          <View >
            <DiscussionHomeFeed
              navigation={navigation}
              listType={"Home"}
              ActiveTab={''}

            />
          </View>
        ) : (
          <View
            style={{
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center"
            }}
          >
            <Icon
              color={"#000"}
              iconStyle={{
                color: "#fff",
                justifyContent: "center",
                alignItems: "center",
                alignSelf: "center"
              }}
              reverse
              name="sticky-note"
              type="font-awesome"
              size={20}
              containerStyle={{
                alignSelf: "center"
              }}
            />
            <Text
              style={{
                fontSize: 12,
                fontFamily: ConstantFontFamily.defaultFont,
                color: "#000",
                alignSelf: "center"
              }}
            >
              <Text
                onPress={() => this.loginHandle()}
                style={{
                  textDecorationLine: "underline",
                  fontFamily: ConstantFontFamily.defaultFont
                }}
              >
                Login
              </Text>{" "}
              to see bookmarked posts
            </Text>
          </View>
        ))
    }

    else if (setHeaderIcon == "New" || tabType == "New") {

      return (
        <View >
          < NewHomeFeed
            navigation={navigation}
            listType={"Home"}
            ActiveTab={''}

          />
        </View>)

    }
    else if (setHeaderIcon == "Trending" || tabType == "Trending") {


      return (
        <View >
          <TrendingHomeFeed
            navigation={navigation}
            listType={"Home"}
            ActiveTab={''}

          />
        </View>
      )
    }
    else {
      return (
        <View >
          <TrendingHomeFeed
            navigation={navigation}
            listType={"Home"}

            ActiveTab={''}

          />
        </View>
      )
    }
  }


  loginHandle = () => {
    this.props.loginModalStatus(true);
  };

  listScroll = value => {
    this.setState({
      feedY: value
    });
  };


  render() {

    const { screenName, windowSize, leftPanelModalFunc, navigation, show, showDiscussion, PostDetails } = this.props
    const { width, height } = windowSize

    const { setHeaderIcon, loginStatus, focused, commentDelay } = this.state


    return (
      <View style={[ButtonStyle.mainContainer, {
      }]}>


        {
          Platform.OS != 'web' ? (
            <Animated.View
              style={{
                position: Platform.OS == "web" ? "sticky" : null,
                top: 0,
                left: 0,
                right: 0,
                zIndex: 10,
                overflow: "hidden",
                borderRadius: 0,
              }}
            >
              <View
                style={{
                  height: width <= 750 ? 50 : 42
                }}
              >
                {this.props.loginStatus == "1" ?
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#000'
                  }}>

                    <TouchableOpacity
                      onPress={() => {
                        leftPanelModalFunc(true)
                      }}
                      style={{
                        flexDirection: "row",
                        backgroundColor: width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        marginLeft: 5,
                        alignItems: 'center'
                      }}
                    >
                      <Image
                        source={require("../assets/image/logolastOne.png")}
                        style={{
                          alignSelf: "center",
                          height: 35,
                          width: 35,
                        }}
                        resizeMode={"contain"}
                      />

                    </TouchableOpacity>

                    <View style={{ width: '75%', marginHorizontal: 5, }}>
                      <Suspense fallback={null}>

                        <SearchInputWeb
                          navigation={navigation}
                          displayType={"web"}
                          press={status => {
                            this.setState({ showSearchIcon: status });
                          }}
                          nameInput={this.nameInput}
                        />
                      </Suspense>

                    </View>
                    <HeaderRightDashboard/>
                    {/* <Menu>
                      <MenuTrigger >

                        <Icon
                          name={setHeaderIcon == 'Trending' ? 'clock-o' :
                            setHeaderIcon == 'New' ? 'fire' :
                              setHeaderIcon == 'Bookmarks' ? 'bookmark' : 'clock-o'}
                          type={setHeaderIcon == 'New' ? 'simple-line-icon' : 'font-awesome'}
                          color="#FFF"
                          style={{ width: 40 }}
                        />

                      </MenuTrigger>

                      <MenuOptions
                        optionsContainerStyle={{
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: "#c5c5c5",
                          shadowColor: "transparent",

                        }}

                        customStyles={{
                          optionsContainer: {
                            position: 'absolute',
                            marginTop: 50,

                          },
                          optionWrapper: { padding: 5 },
                        }}
                      >
                        <MenuOption onSelect={() => this.selectTab("Trending")}>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                            <Icon
                              name='clock-o'
                              type='font-awesome'
                              color="#000"
                              style={{ marginRight: 10 }}
                            />
                            <Text style={{
                              textAlign: "center",
                              color: "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}>Trending</Text></View>
                        </MenuOption>
                        <MenuOption onSelect={() => this.selectTab("New")}>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              name='fire'
                              type='simple-line-icon'
                              color="#000"
                              style={{ marginRight: 10 }}
                            />
                            <Text style={{
                              textAlign: "center",
                              color: "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}>New</Text>
                          </View>
                        </MenuOption>
                        <MenuOption onSelect={() => this.selectTab("Bookmarks")}>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              name='bookmark'
                              type='font-awesome'
                              color="#000"
                              style={{ marginRight: 10 }}
                            />
                            <Text style={{
                              textAlign: "center",
                              color: "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}>Bookmarks</Text>
                          </View>
                        </MenuOption>
                      </MenuOptions>
                    </Menu> */}


                  </View>
                  :
                  <View style={{
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    backgroundColor: '#000'
                  }}>


                    <TouchableOpacity
                      onPress={this.goToScrollTop}
                      style={{

                        flexDirection: "row",
                        backgroundColor: width <= 750 ? "f4f4f4" : "#000",
                        height: 50,
                        alignItems: 'center',
                        justifyContent: 'center',
                        width: '10%',
                        marginHorizontal: 10

                      }}
                    >
                      <Image
                        source={require("../assets/image/logolastOne.png")}
                        style={{
                          marginRight: 5,
                          alignSelf: "center",
                          height: 35,
                          width: 35,

                        }}
                        resizeMode={"contain"}
                      />

                    </TouchableOpacity>
                    
                    <HeaderRightDashboard/> 
                    {/* <Menu>
                      <MenuTrigger >
                        <Icon
                          name={setHeaderIcon == 'Trending' ? 'clock-o' :
                            setHeaderIcon == 'New' ? 'fire' :
                              setHeaderIcon == 'Bookmarks' ? 'bookmark' : 'clock-o'}
                          type={setHeaderIcon == 'New' ? 'simple-line-icon' : 'font-awesome'}
                          color="#FFF"
                          style={{ width: 40 }}
                        />

                      </MenuTrigger>

                      <MenuOptions
                        optionsContainerStyle={{
                          borderRadius: 5,
                          borderWidth: 1,
                          borderColor: "#c5c5c5",
                          shadowColor: "transparent",

                        }}

                        customStyles={{
                          optionsContainer: {
                            position: 'absolute',
                            marginTop: 50,
                          },
                          optionWrapper: { padding: 5 },
                        }}
                      >
                        <MenuOption onSelect={() => this.selectTab("Trending")}>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', }}>
                            <Icon
                              name='clock-o'
                              type='font-awesome'
                              color="#000"
                              style={{ marginRight: 10 }}
                            />
                            <Text style={{
                              textAlign: "center",
                              color: "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}>Trending</Text>
                          </View>
                        </MenuOption>
                        <MenuOption onSelect={() => this.selectTab("New")}>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              name='fire'
                              type='simple-line-icon'
                              color="#000"
                              style={{ marginRight: 10 }}
                            />
                            <Text style={{
                              textAlign: "center",
                              color: "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}>New</Text>
                          </View>
                        </MenuOption>
                        <MenuOption onSelect={() => this.selectTab("Bookmarks")}>
                          <View style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center' }}>
                            <Icon
                              name='bookmark'
                              type='font-awesome'
                              color="#000"
                              style={{ marginRight: 10 }}
                            />
                            <Text style={{
                              textAlign: "center",
                              color: "#000",
                              fontFamily: ConstantFontFamily.defaultFont,
                            }}>Bookmarks</Text>
                          </View>
                        </MenuOption>
                      </MenuOptions>
                    </Menu> */}

                  </View>
                }
              </View>
            </Animated.View>

          ) : null}
        <View
          style={{
            flex: 1,
            flexDirection: "row",
            width: width > 1200 ? width : '100%',
            justifyContent: "center",
            height: '100%',
            marginLeft: 'auto'
          }}

        >
          {Platform.OS == "web" && Dimensions.get('window').width > 750 ?
            <LeftPanel
              navigation={navigation}
            /> : null}
          {
            width <= 750 ?

              <View
                style={{
                  flex: 1,
                }}

              >
                {this.renderTabViewForMobile()}
              </View>

              :
              !show &&
              <View
                style={[ButtonStyle.DashboardTabViewStyle, {
                  width: width > 1200 && width < 1600 ? 450 :
                    width > 1600 ? 600 : (width - 310),
                  backgroundColor: Colors.whiteBackground,
                  borderColor: Colors.lightGray,
                  height: height - 60
                }]}
              >
                <View
                  style={
                    ButtonStyle.TabbarBorderStyleForDashboard}
                >
                  {["Trending", "New", "Discussion"].map((e, index) => {
                    return (
                      <TouchableOpacity key={index} style={{
                        backgroundColor: 'transparent',
                        shadowColor: "transparent",
                      }}
                        onPress={() => {
                          this.setState({
                            focused: e
                          })
                        }}>
                        <Text style={[ButtonStyle.tabbarTitleStyle, {
                          color: focused == e ? Colors.blueColor : "#D3D3D3",
                          width: "100%",
                          fontWeight: focused == e ? 'bold' : '100'
                        }]}>{e}</Text>
                      </TouchableOpacity>
                    )
                  })}
                </View>
                {this._renderScene()}
              </View>
          }

          {width > 1200 || show ?

            <View
              style={[

                {
                  width: width > 1200 && width < 1600 ? 450 :
                    width > 1600 ? 600 : (width - 310),
                  marginBottom: 5,
                  borderLeftColor: Colors.lightGray,
                  borderLeftWidth: 1,
                  height: Dimensions.get('window').height - 45,
                  backgroundColor: Colors.whiteBackground,
                }]}
            >
              <View style={{ width: '97%', marginLeft: 20, height: height - 60, }}>
                {width < 1200 && show ?
                  <View style={{
                    height: 55,
                    alignItems: "center",
                    paddingHorizontal: 10,
                    paddingVertical: 10,
                    marginLeft: 0,
                    width: '100%',
                    borderBottomWidth: 1,
                    borderColor: '#d3d3d3',
                    justifyContent: show ? 'flex-start' : 'center',
                    alignItems: 'center',
                    flexDirection: 'row',
                    backgroundColor: '#fff',
                    marginBottom: 10,
                  }}>
                    {show == true ?
                      <TouchableOpacity style={[ButtonStyle.headerBackStyle, { width: '20%' }]}
                        onPress={() => showDiscussion(false)}>
                        <Icon
                          color={"#000"}
                          name="angle-left"
                          type="font-awesome"
                          size={40}

                        />
                      </TouchableOpacity> : null}
                    < Text style={[ButtonStyle.tabbarTitleStyle, { width: show ? '55%' : '100%', textAlign: 'center', }]}> Discussion</Text>
                  </View> : null}


                <CommentDetailScreen
                  type="dashboard"
                  navigation={navigation}
                  postId={PostDetails && PostDetails?.node?.id}
                  listScroll={this.listScroll}
                  commentDelay={commentDelay}
                  showScrollIntoView={this.showScrollIntoView}
                  ActiveTab={''}
                />
              </View>

            </View> : null
          }
        </View >
      </View >
    );
  }
}

const mapStateToProps = state => ({
  loginStatus: state.UserReducer.loginStatus,
  show: state.AdminTrueFalseReducer.showDiscussions,
  windowSize: state.windowResizeReducer.windowResize,
  profileData: state.LoginUserDetailsReducer.userLoginDetails,
  screenName: state.screenNameReducer.screen,
  tabType: state.AdminTrueFalseReducer.tabType
});

const mapDispatchToProps = dispatch => ({
  loginModalStatus: payload => dispatch(setLOGINMODALACTION(payload)),
  showDiscussion: (payload) => dispatch(showDiscussionReducer(payload)),
  screen: (payload) => dispatch(screen(payload)),
  changeLoginStatus: payload => dispatch(setLoginStatus(payload)),
  setTerm: (payload) => dispatch(setTerm(payload)),
  setInviteModal: (payload) => dispatch(setInviteLinkStatus(payload)),
  setUsernameModalStatus: (payload) =>
    dispatch(setUSERNAMEMODALACTION(payload)),
  leftPanelModalFunc: (payload) => dispatch(leftPanelModalFunc(payload)),
  searchOpenBarStatus: (payload) => dispatch(searchOpenBarStatus(payload)),
});

export default compose(connect(mapStateToProps, mapDispatchToProps))(
  DashboardScreen
);
